<template>
  <!--车辆管理-->
  <div style="padding: 20px;">
    <div v-if="showEdit == false && showDetails == false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="90px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键词:">
                    <el-input
                        clearable
                        v-model="where.keyword"
                        placeholder="请输入关键词"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="服务商:">
                    <el-input
                        clearable
                        v-model="where.facilitator"
                        placeholder="请输入服务商"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="车辆状态:">
                    <el-select
                        clearable
                        v-model="where.hold_condition"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                        v-for="(item) in dict.type.vehiclestate"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label="车辆类型:">
                    <el-select
                        clearable
                        v-model="where.vehicle_mold"
                        placeholder="请选择"
                        class="ele-fluid">
                      <el-option
                        v-for="(item) in dict.type.vehicletype"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :span="24" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="reload">查询
                    </el-button>
                    <el-button @click="reset">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openEdit(null)" v-auths="[`${$config.uniquePrefix}vehicle:vehicleManagement:save`]">
                  <!--el-icon-devops 是自己命名的-->
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">新增车辆</span>
                </el-button>
                <!--<el-button class="custom-button" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;">-->
                <!--  <i class="el-icon-devops" />-->
                <!--  <span style="vertical-align: middle">批量导入开通</span>-->
                <!--</el-button>-->
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >
              <!--车辆类型-->
              <template v-slot:vehicle_mold= "{ scope }">
                <div v-for="(item) in dict.type.vehicletype">
                  <span v-if="scope.row.vehicle_mold === parseInt(item.value)">{{item.label}}</span>
                </div>
              </template>


              <!-- 车辆状态 -->
              <template v-slot:hold_condition= "{ scope }">
                <div v-for="(item) in dict.type.vehiclestate">
                  <span v-if="scope.row.hold_condition === parseInt(item.value)">{{item.label}}</span>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="warning" @click="openDetails(scope.row)" v-auths="[`${$config.uniquePrefix}vehicle:vehicleManagement:info`]">详情</el-link>
                <!--<el-link :underline="false" type="danger" v-auths="[`${$config.uniquePrefix}custom:deactivate`]" @click="CreatePenaltyOrder(1)">处罚</el-link>-->
                <el-link :underline="false" type="warning" style="margin-right: 10px;" @click="openEdit(scope.row)" v-auths="[`${$config.uniquePrefix}vehicle:vehicleManagement:put`]">编辑</el-link>
                <!--<el-popconfirm-->
                <!--    title="确定删除吗？"-->
                <!--    @confirm="del(scope.row)"-->
                <!--&gt;-->
                <!--  <el-link slot="reference" :underline="false" type="info">删除</el-link>-->
                <!--</el-popconfirm>-->
              </template>
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <!--车辆编辑模块组件-->
    <Edit v-if="showEdit == true" :id="pid" :dict="dict" :data="showContent" @showEdit="showedit" @done="getList"/>

    <!--车辆详情信息-->
    <Details v-if="showDetails == true" :id="pid" :dict="dict" :data="DetailsContent" @showDetails="showdetails"/>

  </div>
</template>

<script>
//引入车辆编辑组件
import Edit from '../edit/index.vue'
//引入车辆详情组件
import Details from '../details/index.vue'

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getVehicle,
  Delvehicles
} from '@/api/vehicle'
import Vue from "vue";
import TechnicianEdit from "@/views/yunli/edit/edit.vue";

export default {
  // 组件生效
  components: {
    TechnicianEdit,
    PackageSortElement,
    Edit,
    Details
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "车牌号",
          // type: "index",
          // width: "70"
          prop: "license_plate_number",
          isShow: true
        },
        {
          label: "车辆是否认证",
          prop: "",
          isShow: true
        },
        {
          label: "司机名称",
          prop: "",
          isShow: true
        },
        {
          label: "所属服务商",
          // type: "index",
          // width: "70"
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "车辆类型",
          prop: "vehicle_mold",
          slot: 'vehicle_mold',
          isShow: true
        },
        {
          label: "车架号",
          prop: "vin",
          isShow: true
        },
        {
          label: "发动机号",
          prop: "engine_number",
          isShow: true
        },
        {
          label: "所有人",
          prop: "holder",
          isShow: true
        },
        {
          label: "品牌型号",
          prop: "vehicle_brand",
          isShow: true
        },
        {
          label: "商业险到期日",
          prop: "rot_expiration_time",
          isShow: true
        },
        {
          label: "拖挂险险到期日",
          prop: "ci_expiration_time",
          isShow: true
        },
        {
          label: "状态",
          prop: "hold_condition",
          slot: 'hold_condition',
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //车辆编辑
      showContent:null,
      showEdit:false,

      //车辆详情
      showDetails:false,
      DetailsContent:null,

      //字典
      dict: {
        type:{}
      },

      pid:0,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '车辆字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });
    console.log(this.dict)

    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      getVehicle(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    /* 刷新表格 */
    reload() {
      this.getList();
    },
    /* 重置搜索 */
    reset() {
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      };
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 启用状态
    Enable(row){
      // 参数
      let params = {
        status: '1'
      }
      set_statusApi(row.id,params).then(res => {
        this.$message.success(res.msg);
        this.getList();
      }).catch(err => {
        this.$message.error(err.msg);
      })
    },
    Disable(row){
      // 参数
      let params = {
        status: '0'
      }
      set_statusApi(row.id,params).then(res => {
        this.$message.success(res.msg);
        this.getList();
      }).catch(err => {
        this.$message.error(err.msg);
      })
    },

    // 删除
    del(row){
      console.log(row);
      Delvehicles(row.id).then(res => {
        console.log(res)
        if(res.code === 200){
          this.$message.success(res.msg);
          this.getList();
        }else {
          this.$message.success(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //点击新增车辆
    openEdit(row){
      console.log(row);
      if(row === null){
        this.showContent = row;
        this.showEdit = true;
      }else {
        this.pid = row.id;
        this.showContent = row;
        this.showEdit = true;
      }
    },
    //接收编辑子组件传值
    showedit(value){
      this.showEdit = value;
    },

    //点击详情
    openDetails(row){
      this.pid = row.id;
      this.showDetails = true;
      this.DetailsContent = row;
    },
    //接收详情子组件传值
    showdetails(value){
      this.showDetails = value;
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
