<template>
  <!--车辆详情-->
  <div>
    <div class="title">
      <div class="title_t">
        <span>车辆信息</span>
      </div>
      <div class="title_r" @click="returnPage">
        <span>返回</span>
      </div>
    </div>

    <el-form
        ref="form"
        :model="form"
        label-width="150px">

      <el-card shadow="never">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #202033;">车辆信息</span>
        </div>

        <el-row :gutter="15">
          <!--<el-col :span="5">-->
          <!--  <div style="margin-top: 10px;">-->
          <!--    <div class="upload_bg_shouchi">-->
          <!--      <img v-if="form.driving_license != ''" :src="form.driving_license" alt="" style="width: 100%;height: 500px;">-->
          <!--      <el-empty description="暂无图片" v-else></el-empty>-->
          <!--    </div>-->
          <!--  </div>-->
          <!--  <div style="margin-top: 10px;">-->
          <!--    <div class="upload_bg_shouchi">-->
          <!--      <img v-if="form.bodywork != ''" :src="form.bodywork" alt="" style="width: 100%;height: 500px;">-->
          <!--      <el-empty description="暂无图片" v-else></el-empty>-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</el-col>-->
          <el-col :span="5">
            <div style="margin-top: 10px;">
              <div class="upload_bg_shouchi">
                <el-upload
                  :limit="1"
                  :class="{hide_box: handupload_btn}"
                  :on-change="handchange"
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload3"
                  :file-list="fileList3"
                  list-type="picture-card"
                  :on-preview="handPreview"
                  :on-remove="handhandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                  <div style="font-size: 12px;font-weight: 500;color: #ff9b05;margin-top: 20px;">行驶证上传</div>
                </el-upload>
                <el-dialog :visible.sync="handdialogVisible">
                  <img width="100%" :src="handdialogImageUrl" alt="">
                </el-dialog>
              </div>
            </div>
            <div style="margin-top: 10px;">
              <div class="upload_bg_shouchi">
                <el-upload
                  :limit="1"
                  :class="{hide_box: bodyupload_btn}"
                  :on-change="bodychange"
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload4"
                  :file-list="fileList4"
                  list-type="picture-card"
                  :on-preview="bodyPreview"
                  :on-remove="bodyhandleRemove">
                  <!--<i class="el-icon-plus"></i>-->
                  <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
                  <div style="font-size: 12px;font-weight: 500;color: #ff9b05;margin-top: 20px;">车身45°</div>
                </el-upload>
                <el-dialog :visible.sync="bodydialogVisible">
                  <img width="100%" :src="bodydialogImageUrl" alt="">
                </el-dialog>
              </div>
            </div>
          </el-col>
          <el-col :span="19">
            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="车牌号">
                  <el-input clearable :disabled="true" v-model="form.license_plate_number" placeholder="请输入车牌号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车辆归属">
                  <el-select
                    :disabled="true"
                    clearable
                    v-model="form.affiliation"
                    placeholder="请选择"
                    class="ele-fluid">
                    <el-option
                      v-for="(item,index) in service"
                      :label="item.name"
                      :value="item.value"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="所有人">
                  <el-input clearable :disabled="true" v-model="form.holder" placeholder="请输入所有人"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="状态">
                  <el-select
                      :disabled="true"
                      clearable
                      v-model="form.hold_condition"
                      placeholder="请选择"
                      class="ele-fluid">
                      <el-option
                        v-if="item.label != '全部'"
                        v-for="(item) in dict.type.vehiclestate"
                        :label="item.label"
                        :value="parseInt(item.value)"
                      />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="汽车类型">
                  <el-select
                    :disabled="true"
                    clearable
                    v-model="form.vehicle_mold"
                    placeholder="请选择"
                    class="ele-fluid">
                    <el-option
                      v-if="item.label != '全部'"
                      v-for="(item) in dict.type.vehicletype"
                      :label="item.label"
                      :value="parseInt(item.value)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="品牌型号">
                  <el-input clearable :disabled="true" v-model="form.vehicle_brand" placeholder="请输入品牌型号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="车架号码">
                  <el-input clearable :disabled="true" v-model="form.vin" placeholder="请输入车架号码"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发动机号">
                  <el-input clearable :disabled="true" v-model="form.engine_number" placeholder="请输入发动机号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="黄蓝牌">
                  <el-select
                      :disabled="true"
                      clearable
                      v-model="form.plate_type"
                      placeholder="请选择"
                      class="ele-fluid">
                    <el-option
                      v-for="(item) in dict.type.Licenseplatetype"
                      :label="item.label"
                      :value="parseInt(item.value)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="注册日期">
                  <el-date-picker
                      :disabled="true"
                      value-format="yyyy-MM-dd"
                      v-model="form.registration_date"
                      type="date"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="GPS类型">
                  <el-select
                      :disabled="true"
                      clearable
                      v-model="form.gps_type"
                      placeholder="请选择"
                      class="ele-fluid">
                    <el-option
                      v-for="(item) in dict.type.gpsType"
                      :label="item.label"
                      :value="parseInt(item.value)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="终端编号">
                  <el-input clearable :disabled="true" v-model="form.terminal_number" placeholder="请输入发动机号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-card>

      <el-card shadow="never">
        <el-row :gutter="20">
          <el-col :span="8">
            <div style="display: flex;align-items: center;margin-bottom: 10px;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 500;color: #3f4155;">交强险</span>
            </div>
            <div class="upload_bg_jiaoqiangxian">
              <img v-if="form.insurance.mlifta.img_dir != ''" :src="form.insurance.mlifta.img_dir" alt="" style="width: 100%;height: 620px;">
              <el-empty description="暂无图片" v-else></el-empty>
            </div>
            <div style="margin-top: 20px;">
              <el-form-item label="投保公司" label-width="80px">
                <el-input clearable :disabled="true" v-model="form.insurance.mlifta.corporation" placeholder="请输入投保公司"></el-input>
              </el-form-item>
              <el-form-item label="到期时间" label-width="80px">
                <el-date-picker
                    :disabled="true"
                    v-model="form.insurance.mlifta.expiration_time"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="保单号" label-width="80px">
                <el-input clearable :disabled="true" v-model="form.insurance.mlifta.warranty_number" placeholder="请输入保单号"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="display: flex;align-items: center;margin-bottom: 10px;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 500;color: #3f4155;">商业险</span>
            </div>
            <div class="upload_bg_jiaoqiangxian">
              <img v-if="form.insurance.ci.img_dir != ''" :src="form.insurance.ci.img_dir" alt="" style="width: 100%;height: 620px;">
              <el-empty description="暂无图片" v-else></el-empty>
            </div>
            <div style="margin-top: 20px;">
              <el-form-item label="投保公司" label-width="80px">
                <el-input clearable :disabled="true" v-model="form.insurance.ci.corporation" placeholder="请输入投保公司"></el-input>
              </el-form-item>
              <el-form-item label="到期时间" label-width="80px">
                <el-date-picker
                  :disabled="true"
                  v-model="form.insurance.ci.expiration_time"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="保单号" label-width="80px">
                <el-input clearable :disabled="true" v-model="form.insurance.ci.warranty_number" placeholder="请输入保单号"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="display: flex;align-items: center;margin-bottom: 10px;">
              <span style="width: 5px;height: 16px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 500;color: #3f4155;">拖挂险</span>
            </div>
            <div class="upload_bg_jiaoqiangxian">
              <img v-if="form.insurance.rot.img_dir != ''" :src="form.insurance.rot.img_dir" alt="" style="width: 100%;height: 620px;">
              <el-empty description="暂无图片" v-else></el-empty>
            </div>
            <div style="margin-top: 20px;">
              <el-form-item label="投保公司" label-width="80px">
                <el-input clearable :disabled="true" v-model="form.insurance.mlifta.corporation" placeholder="请输入投保公司"></el-input>
              </el-form-item>
              <el-form-item label="到期时间" label-width="80px">
                <el-date-picker
                  :disabled="true"
                  v-model="form.insurance.rot.expiration_time"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="保单号" label-width="80px">
                <el-input clearable :disabled="true" v-model="form.insurance.rot.warranty_number" placeholder="请输入保单号"></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-card>


    </el-form>
  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

import {Detailsvehicles} from "@/api/vehicle";
import {commonfacilitator} from '@/api/yunli'

export default {
  name: 'index',
  // 支柱道具属性
  props: {
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // id
    id:Number
  },
  // 组件生效
  components: {

  },
  // Data数据
  data() {
    return {
      form:{},

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/vehicle',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //行驶证照片
      handdialogImageUrl:'',
      handdialogVisible:false,
      handupload_btn:false,
      fileList3:[],

      //车身
      bodydialogImageUrl:'',
      bodydialogVisible:false,
      bodyupload_btn:false,
      fileList4:[],

      // 服务商
      service:[],
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 获取所有服务商
    this.getcommonfacilitator();
    this.getDetails();
  },
  // 方法集合
  methods: {
    // 获取详情
    getDetails(){
      Detailsvehicles(this.id).then(res => {
        this.form = res.data;
        //行驶证照片
        if(this.form.driving_license != ''){
          let data = {
            url: this.form.driving_license
          };
          this.handupload_btn = true;
          this.fileList3.push(data);
        }
        //车身
        if(this.form.bodywork != ''){
          let data = {
            url: this.form.bodywork
          };
          this.bodyupload_btn = true;
          this.fileList4.push(data);
        }
      })
    },

    // 获取服务商列表包含自己
    getcommonfacilitator(){
      commonfacilitator().then(res => {
        this.service = res.data;
      })
    },

    // 返回上级页面
    returnPage(){
      this.$emit('showDetails',false) ;
    },

    //行驶证照片
    handchange(){
      this.handupload_btn = true;
    },
    handhandleRemove(){
      this.handupload_btn = false;
    },
    handPreview(file){
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },
    successUpload3(file,fileList){
      // 上传成功后的列表
      this.form.driving_license = file.data.urls;
    },


    //车身45
    bodychange(){
      this.bodyupload_btn = true;
    },
    bodyhandleRemove(){
      this.bodyupload_btn = false;
    },
    bodyPreview(file){
      this.bodydialogImageUrl = file.url;
      this.bodydialogVisible = true;
    },
    successUpload4(file,fileList){
      // 上传成功后的列表
      this.form.bodywork = file.data.urls;
    },


  },
}
</script>

<style scoped lang="scss">
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
